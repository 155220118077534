// extracted by mini-css-extract-plugin
export var backgroundTransparent = "rgba(0,0,0,0)";
export var baseColor = "#000";
export var book = "guestbook-module--book--WOVXo";
export var card = "guestbook-module--card--GFcMj";
export var comment = "guestbook-module--comment--FzmRB";
export var container = "guestbook-module--container--PBLNX";
export var date = "guestbook-module--date--C4x+N";
export var defaultForegroundColor = "#d9d9d9";
export var formSection = "guestbook-module--form-section--S4+qB";
export var highlightColor = "#b99128";
export var icon = "guestbook-module--icon--5BOb2";
export var messageButton = "guestbook-module--message-button--i3u99";
export var name = "guestbook-module--name--7XGvX";
export var overlayBackgroundBottom = "linear-gradient(0, #262626, transparent)";
export var overlayBackgroundColor = "#262626";
export var overlayBackgroundTop = "linear-gradient(0, transparent, #262626)";
export var rootBackgroundColor = "#151515";
export var textColor = "#fff";
export var website = "guestbook-module--website--fmC2o";